import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core'
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import {
  KycType,
  KycTypeMap
} from '@sparbanken-syd/spb-savings-account-backend/dist'
import {Interest, InterestService} from '../../services/interest.service'
import {OrientationService} from '../../services/orientation.service'
import {SavingsAccountService} from '../../services/savings-account.service'
import { MatProgressBar } from '@angular/material/progress-bar'
import { BirthComponent } from './birth/birth.component'
import { PrimaryResidenceComponent } from './primary-residence/primary-residence.component'
import { GreenCardComponent } from './green-card/green-card.component'
import { AmericanCitizenshipComponent } from './american-citizenship/american-citizenship.component'
import { TaxResidencyComponent } from './tax-residency/tax-residency.component'
import { PepComponent } from './pep/pep.component'
import { BankRelationshipComponent } from './bank-relationship/bank-relationship.component'
import { IncomeComponent } from './income/income.component'
import { SavingsComponent } from './savings/savings.component'
import { MatError } from '@angular/material/form-field'
import { NextButtonComponent } from '../../components/next-button/next-button.component'


@Component({
    selector: 'spb-kyc',
    templateUrl: './kyc.component.html',
    styleUrls: ['./kyc.component.scss'],
    imports: [ReactiveFormsModule, MatProgressBar, BirthComponent, PrimaryResidenceComponent, GreenCardComponent, AmericanCitizenshipComponent, TaxResidencyComponent, PepComponent, BankRelationshipComponent, IncomeComponent, SavingsComponent, MatError, NextButtonComponent]
})
export class KycComponent implements OnInit{
  @ViewChild('kycFormElement') kycFormElement!: ElementRef
  @Input({required: true}) kycForm!: FormGroup
  public currentStep = 0
  public totalSteps = 9
  public showWarningText = false
  public latestInterest!: Interest
  protected KycType = KycType

  constructor(
    private interestService: InterestService,
    protected savingsAccountService: SavingsAccountService,
    protected orientationService: OrientationService
  ) {
  }

  public ngOnInit() {
    this.interestService.getLatestInterest().subscribe({
      next: interest => this.latestInterest = interest
    })
  }

  public nextStep(form: AbstractControl) {
    this.showWarningText = false

    if (this.currentStep === 6 && (!form.value.openSavingsAccount && !form.value.existingSavingsAccountNumber)) {
      this.showWarningText = true
      return
    }

    if (this.currentStep < this.totalSteps) {
      this.currentStep++
      this.scrollToTop()
    }
  }

  public previousStep() {
    this.showWarningText = false
    if (this.currentStep > 0) {
      this.currentStep--
      this.scrollToTop()
    }
  }

  public disabledButton() {
    // Use KycTypeMap to convert the currentStep enum value to its string representation
    const stepName = KycTypeMap.get(this.currentStep)

    // Access the form control based on the string representation
    const validationFunction = () => !this.kycForm.controls[`${stepName}Form`].valid

    // Return the result of the validation function (true or false)
    return validationFunction()
  }

  protected getFormGroup(control: AbstractControl) {
    return control as FormGroup
  }

  private scrollToTop() {
    this.kycFormElement.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}
