import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core'
import {FormGroup} from '@angular/forms'
import {Router} from '@angular/router'
import {BankIdComponent} from '@sparbanken-syd/sparbanken-syd-bankid'
import {AccountApplication} from '@sparbanken-syd/spb-savings-account-backend'
import {filter} from 'rxjs'
import {environment} from '../../../environments/environment'
import {SavingsAccountService} from '../../services/savings-account.service'
import { MatError } from '@angular/material/form-field'

@Component({
    selector: 'spb-summary',
    templateUrl: './summary.component.html',
    imports: [BankIdComponent, MatError]
})
export class SummaryComponent implements OnInit, OnChanges {
  @Input({required: true}) savingsAccountForm!: FormGroup
  @Input({required: true}) stepperTrigger!: boolean
  @ViewChild(BankIdComponent) bankId?: BankIdComponent

  /**
   * Show an error message.
   */
  public errorMessage = ''

  public accountApplication = {id: ''} as AccountApplication
  public signed: string | null = null
  protected readonly requestApi = environment.requestApi
  protected readonly authServiceUrl = environment.authServiceUrl
  protected readonly domain = environment.domain

  constructor(
    protected savingsAccountService: SavingsAccountService, private router: Router
  ) {

  }

  public ngOnInit() {
    this.savingsAccountForm.valueChanges.pipe(
      filter(() => this.savingsAccountForm.valid)
    ).subscribe()
  }

  public ngOnChanges() {
    /**
     * Save the form at the last step (before the customer signs it)
     */
    if (this.stepperTrigger) {
      this.put()
    }
  }

  public put() {
    const request = this.savingsAccountForm.value

    this.savingsAccountService.saveRequest(request).subscribe({
      next: (accountApplication) => {
        this.accountApplication = accountApplication
        this.savingsAccountForm.controls.id.setValue(this.accountApplication.id)
      }
    })
  }

  public submitSignature(token: string) {
    this.errorMessage = ''
    // Get information from received token
    const payload = JSON.parse(atob(token.split('.')[1]))
    // Check that signing personnummer is the same as application's personnummer.
    if (payload.sub !== this.accountApplication.applicantData.personalNumber.replace('-', '')) {
      // Show error message and reset process
      this.errorMessage = 'Personnumret i ansökan stämmer inte med signaturen'
      this.bankId?.reset()
      return
    }

    this.savingsAccountService.addSignature(token, this.accountApplication.id)
      .subscribe({
        next: () => void this.router.navigate(['/complete'],
          {state: {accountApplication: this.accountApplication}})
      })
    this.accountApplication.id = ''
    this.signed = token
  }
}
