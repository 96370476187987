import {Component, Input} from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import {IKycForm} from '@sparbanken-syd/spb-savings-account-backend'
import {OrientationService} from '../../../services/orientation.service'
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio'
import { MatError } from '@angular/material/form-field'

@Component({
    selector: 'spb-american-citizenship',
    templateUrl: './american-citizenship.component.html',
    styleUrl: '../kyc.component.scss',
    imports: [ReactiveFormsModule, MatRadioGroup, MatRadioButton, MatError]
})
export class AmericanCitizenshipComponent {
  @Input({required: true}) americanCitizenshipForm!: FormGroup
  @Input({required: true}) kycForm!: IKycForm

  constructor(protected orientationService: OrientationService) {
  }
}
