import {Routes} from '@angular/router'
import {CompleteComponent} from './home/5-complete/complete.component'
import {HomeComponent} from './home/home.component'

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'complete',
    component: CompleteComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
]