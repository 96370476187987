import {NgClass} from '@angular/common'
import {Component, Input} from '@angular/core'
import {FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatOption, MatSelect} from '@angular/material/select'
import {SPBConstants} from '@sparbanken-syd/sparbanken-syd-theme'
import {OrientationService} from '../../../services/orientation.service'


@Component({
  selector: 'spb-primary-residence',
  templateUrl: './primary-residence.component.html',
  styleUrl: '../kyc.component.scss',
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatSelect, MatOption, NgClass, MatError]
})
export class PrimaryResidenceComponent {
  @Input({required: true}) primaryResidenceForm!: FormGroup

  protected readonly countryList = [...SPBConstants.CountryList]
    .sort(a => a.name === 'Sverige' ? -1 : 1)

  constructor(protected orientationService: OrientationService) {
  }
}
