import {Component, Input} from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio'

@Component({
    selector: 'spb-green-card',
    templateUrl: './green-card.component.html',
    styleUrl: '../kyc.component.scss',
    imports: [ReactiveFormsModule, MatRadioGroup, MatRadioButton]
})
export class GreenCardComponent {
  @Input({required: true}) greenCardForm!: FormGroup
}
