import {Component} from '@angular/core'

@Component({
    selector: 'spb-complete',
    templateUrl: './complete.component.html',
    styleUrl: './complete.component.scss'
})
export class CompleteComponent {

}
