import {Component, Input} from '@angular/core'
import {FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {SavingsAccountService} from '../../../services/savings-account.service'

@Component({
  selector: 'spb-income',
  templateUrl: './income.component.html',
  styleUrl: '../kyc.component.scss',
  imports: [ReactiveFormsModule, MatCheckbox, MatFormField, MatLabel, MatInput, MatError, FormatNumberDirective]
})
export class IncomeComponent {
  @Input({required: true}) incomeForm!: FormGroup

  constructor(protected savingsAccountService: SavingsAccountService) {
  }

  public onCheckboxChange(index: number, checked: boolean, formValue: string) {
    this.savingsAccountService.incomeOptions[index].checked = checked
    if (!checked) {
      this.incomeForm.controls[formValue].setValue(null)
    }
  }
}
