import {Component, Input} from '@angular/core'
import {FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {OrientationService} from '../../../services/orientation.service'
import {SavingsAccountService} from '../../../services/savings-account.service'

@Component({
  selector: 'spb-savings',
  templateUrl: './savings.component.html',
  styleUrl: '../kyc.component.scss',
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatCheckbox, FormatNumberDirective]
})
export class SavingsComponent {
  @Input({required: true}) savingsForm!: FormGroup

  constructor(
    protected savingsAccountService: SavingsAccountService,
    protected orientationService: OrientationService
  ) {
  }

  public onCheckboxChange(index: number, checked: boolean) {
    this.savingsAccountService.savingsSource[index].checked = checked

    // filter the checked values from savingsSource (where money come from)
    const checkedValues = this.savingsAccountService.savingsSource
      .filter(option => option.checked)
      .map(option => option.value)

    // Reset form and patch new values
    this.savingsForm.controls.savingsSource.reset()
    this.savingsForm.controls.savingsSource.patchValue(checkedValues)
  }
}
