<div class="form-holder">
  <mat-stepper
    spbStepperScroll
    [class.small-screen]="orientationService.isSmallScreen$()"
    #stepper
    [linear]="!savingsAccountService.testMode$()"
    [orientation]="orientationService.isSmallScreen$() ?'vertical' : 'horizontal'"
    class="stepper"
  >
    <mat-step [completed]="welcomeForm.valid">
      <ng-template matStepLabel>
        @if (welcomeForm.valid && stepper.selectedIndex !== 0) {
          <span class="stepper-text underline">Välkommen</span>
        } @else {
          <span class="stepper-text">Välkommen</span>
        }
      </ng-template>

      <spb-welcome [welcomeForm]="welcomeForm"></spb-welcome>
      <spb-next-button [valid]="welcomeForm.valid"></spb-next-button>
    </mat-step>

    <mat-step [completed]="applicantsForm.valid">
      <ng-template matStepLabel>
        @if (applicantsForm.valid && stepper.selectedIndex !== 1) {
          <span class="stepper-text underline">Kontaktuppgifter</span>
        } @else {
          <span class="stepper-text">Kontaktuppgifter</span>
        }
      </ng-template>

      <spb-applicants [applicantsForm]="applicantsForm"></spb-applicants>
      <spb-next-button [valid]="applicantsForm.valid"></spb-next-button>
    </mat-step>

    <mat-step [completed]="kycForm.valid">
      <ng-template matStepLabel>
        @if (kycForm.valid && stepper.selectedIndex !== 2) {
          <span class="stepper-text underline">Kundkännedom</span>
        } @else {
          <span class="stepper-text">Kundkännedom</span>
        }
      </ng-template>

      <spb-kyc [kycForm]="kycForm"></spb-kyc>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>
        <span class="stepper-text">Godkänn med BankID</span>
      </ng-template>
      <spb-summary
        [stepperTrigger]="kycForm.valid && stepper.selectedIndex !== 2"
        [savingsAccountForm]="savingsAccountForm"></spb-summary>
    </mat-step>
  </mat-stepper>
</div>
